<template>
    <table>
        <tr>
            <th>Domicile</th>
            <th>Tractor</th>
            <th style="width: 60px;">Person Completion Work</th>
            <th style="width: 120px;">Notes</th>
            <th style="width: 50px;">Date</th>
            <th>Odometer</th>
            <th>Cost</th>
            <th>MMR Task</th>
            <th>OOS</th>
            <th>Scheduled</th>
        </tr>
        <tr v-for="(item, index) in data" :key="index">
            <td v-for="(val, name, idx) in item" :key="idx" :style="idx == 2 ? { 'width' : '60px' } : idx == 3 ? { 'width' : '120px' } : idx == 4 ? { 'width': '50px' } : { 'width' : 'calc(100%-230px)' }">
                {{ val }}
            </td>
        </tr>
    </table>
</template>

<script>
    
export default {
    props: {
        data: Array,
    },
}

</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: center;
  padding: 1px;
  border: 1px solid #ddd;
}

th {
  background-color: #4472c4;
  color: white;
  font-size: 6px;
}

td {
    font-size: 8px;
    text-align: center;
}

h5 {
    text-align: center;
}

span {
    font-weight: bold;
}
</style>