<template>
    <b-card no-body>
        <b-card-header>
            <h3>Maintenance Form List</h3>
        </b-card-header>

        <b-card-body>
            <div class="d-flex">
                <b-button
                    v-b-modal.modal-center
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                >
                    + Add Repair
                </b-button>
            </div>
            <div class="d-flex justify-content-between flex-wrap">
                <!-- sorting  -->
                <b-form-group
                    label-align-sm="left"
                    label-cols-sm="2"
                    label-for="sortBySelect"
                    class="mr-1 mb-md-0"
                >
                </b-form-group>

                <!-- filter -->
                <b-form-group
                    label="Filter"
                    label-cols-sm="2"
                    label-align-sm="left"
                    label-size="md"
                    label-for="filterInput"
                    class="mb-0"
                >
                    <b-input-group>
                        <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                        />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Clear
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <div class="flex-wrap">               
                <b-row class="d-flex align-items-center mb-1">
                    <b-col md="3">
                        <b-form-group label="From">
                            <b-form-datepicker
                                id="example-datepicker1"
                                v-model="fromDate"
                                class="mb-1"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="To">
                            <b-form-datepicker
                                id="example-datepicker2"
                                v-model="toDate"
                                class="mb-1"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="2" sm="4" xs="5">
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            @click="search"
                        >
                            Search
                        </b-button>
                    </b-col>
                    <b-col md="3">
                        <b-button variant="primary" @click="exportPDF">
                            <feather-icon
                                icon="DownloadIcon"
                                class="mr-50"
                            />PDF</b-button
                        >
                        <b-button variant="info" @click="csvExport" class="ml-1">
                            <feather-icon
                                icon="DownloadIcon"
                                class="mr-50"
                            />CSV</b-button
                        >
                    </b-col>
                </b-row>
            </div>
        </b-card-body>
        
        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
        >
            <template #cell(photo)="data">
                <div v-on:click="ViewPhoto(data.item.photo)">
                    <b-avatar :src="data.item.photo"   />

                </div>
            </template>

            <template #cell(DOMFP)="data">
                <div v-on:click="ViewNotes(data.item.DOMFP)">
                    <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                    title="View Notes"
                />

                </div>
            </template>
            <template #cell(status)="data">
                <b-badge :variant="status[1][data.value]">
                    {{ status[0][data.value] }}
                </b-badge>
            </template>
            <template #cell(actions)="data">
                <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                >
                    <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                    </template>

                    <b-dropdown-item
                        v-b-modal.modal-center
                        @click="onEdit(data.item)"
                    >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="onRemove(data.item.id)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </b-table>

        <div class="hiddenContainer">
            <pdfexport ref="contentToExport" forcePageBreak=".page-break" :margin="'0.5cm'" :paper-size="'A4'" :keepTogether="'.prevent-split'" fileName="FleetMaintenance">
                <div v-if="pdfItems.length">
                    <ExportToPDF 
                        :data="pdfItems"
                    />
                </div>
            </pdfexport>
        </div>

        <b-modal
            size="lg"
            id="modal-center"
            centered
            title="Maintenance Form"
            ok-title="Save"
            @ok="onSave"
            ok-only
            @close="onClose"
        >
            <b-row>
                <b-col md="6">
                    <b-form-group
                        label="Domicile"
                        label-for="h-first-name"
                        label-cols-md="4"
                    >
                        <v-select
                            id="h-first-name"
                            v-model="maintenance.domicile_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="domicile"
                            :options="domiciles"
                            @input="onChangeDomicile"
                        />
                        <p v-if="requireMaintenance.domicile_id" class="text-danger">This is required.</p>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Tractor"
                        label-for="h-first-name"
                        label-cols-md="4"
                    >
                        <v-select
                            id="h-first-name"
                            v-model="maintenance.tractor_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="tractor_id"
                            :options="tractors"
                        />
                        <p v-if="requireMaintenance.tractor_id" class="text-danger">This is required.</p>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Person Completing Work"
                        label-for="h-first-name"
                        label-cols-md="4"
                    >
                        <b-form-input
                            id="h-first-name"
                            v-model="maintenance.person_completing_work"
                            placeholder="Enter Completing Work.."
                        />
                        <p v-if="requireMaintenance.person_completing_work" class="text-danger">This is required.</p>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Date"
                        label-for="h-first-name"
                        label-cols-md="4"
                    >
                        <b-form-input
                            type="date"
                            v-model="maintenance.date"
                            id="h-first-name"
                        />
                        <p v-if="requireMaintenance.date" class="text-danger">This is required.</p>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Odometer"
                        label-for="basicInput"
                        label-cols-md="4"
                    >
                        <b-form-input
                            type="number"
                            id="basicInput"
                            v-model="maintenance.odometer"
                            placeholder="Enter Odometer.."
                        />
                        <p v-if="requireMaintenance.odometer" class="text-danger">This is required.</p>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Costs"
                        label-for="basicInput"
                        label-cols-md="4"
                    >
                        <b-form-input
                            type="number"
                            id="basicInput"
                            v-model="maintenance.costs"
                            placeholder="Enter Costs.."
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        class="d-flex align-items-center"
                        label="MMR Task"
                        label-for="basicInput"
                        label-cols-md="4"
                    >
                        <b-form-radio-group
                            id="basicInput"
                            v-model="maintenance.mmr_task"
                            :options="elements"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                            class="demo-inline-spacing"
                        />
                        <p v-if="requireMaintenance.mmr_task" class="text-danger">This is required.</p>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        class="d-flex align-items-center"
                        label="Out Of Service"
                        label-for="service"
                        label-cols-md="4"
                    >
                        <b-form-radio-group
                            id="service"
                            v-model="maintenance.out_of_service"
                            :options="elements"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                            class="demo-inline-spacing"
                        />
                        <p v-if="requireMaintenance.out_of_service" class="text-danger">This is required.</p>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Scheduled"
                        label-for="schedule"
                        label-cols-md="4"
                    >
                        <b-form-radio-group
                            id="schedule"
                            v-model="maintenance.shceduled"
                            :options="elements"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                            class="demo-inline-spacing"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" class="mb-2">
                    <b-form-textarea
                        id="textarea-default"
                        v-model="maintenance.domfp"
                        value-field="item"
                        text-field="name"
                        placeholder="Enter description of maintenance performed.."
                    />
                </b-col>
                <b-col lg="6">
                    <b-media class="mb-2 ml-2">
                        <template #aside>
                            <b-avatar
                                ref="previewEl"
                                :src="avatar ? maintenance.photo = avatar : maintenance.photo"
                                variant="light-primary"
                                size="90px"
                                rounded
                            />
                        </template>
                        <div class="d-flex flex-wrap">
                            <b-button
                                variant="primary"
                                @click="$refs.refInputEl.click()"
                            >
                                <input
                                    ref="refInputEl"
                                    type="file"
                                    class="d-none"
                                    @input="inputImageRenderer"
                                />
                                <span class="d-none d-sm-inline">upload</span>
                            </b-button>
                            <b-button
                                variant="outline-secondary"
                                @click="onDeleteImage"
                                class="ml-1"
                            >
                                <span class="d-none d-sm-inline">Remove</span>
                            </b-button>
                        </div>
                    </b-media>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal
            ref="PhotoModal"
            id="PhotoModal"
            centered
            title="View Photo"
            v-bind:hide-footer="true"
            class="photoModal"
        >
        <div v-if="imgPreview != null">
            <img :src="imgPreview" style="max-width:100%;">
        </div>
        </b-modal>


        <b-modal
            size="lg"
            ref="NotesModal"
            id="NotesModal"
            centered
            title="View Notes"
            v-bind:hide-footer="true"
        >
        {{ notesPreview }}
        </b-modal>
    </b-card>
</template>

<script>
import {
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTextarea,
    BMedia,
    BDropdown,
    BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import moment, { months } from "moment";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import axios from "axios";
import { serverUri } from "@/config";
import { getUserData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ExportToPDF from "./ExportToPDF.vue";
import { PDFExport } from "@progress/kendo-vue-pdf";
import exportFromJSON from "export-from-json";

export default {
    components: {
        BRow,
        BCol,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCard,
        BCardHeader,
        BCardBody,
        BFormDatepicker,
        BFormRadioGroup,
        BFormTextarea,
        BMedia,
        BDropdown,
        BDropdownItem,
        vSelect,        
        ExportToPDF,
        pdfexport: PDFExport,
    },
    data() {
        return {
            elements: ["Yes", "No"],
            selectedMMR: null,
            selectedService: null,
            selectedSchedule: null,
            date: null,
            status: [],
            imgPreview:'',
            notesPreview:'',
            selectedStatus: null,
            domiciles: [],
            selectedDomicile: null,
            tractors: [],
            selectedTractor: null,
            perPage: 5,
            pageOptions: [5, 10, 25],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
            fields: [
                {
                    key: "id",
                    label: "#",
                },
                {
                    key: "domicile_id",
                    label: "DOMICILE",
                    sortable: true,
                },
                {
                    key: "tractor_id",
                    label: "TRACTOR #",
                    sortable: true,
                },
                {
                    key: "person_completing_work",
                    label: "PERSON COMPLETION WORK",
                    sortable: true,
                },
               
                {
                    key: "date",
                    label: "DATE",
                    sortable: true,
                    formatter: (value) => {
                            return moment(value).format("MM/DD/YYYY");
                    },
                },
                {
                    key: "odometer",
                    label: "ODOMETER",
                    sortable: true,
                },
                {
                    key: "costs",
                    label: "COST",
                    sortable: true,
                },
                {
                    key: "mmr_task",
                    label: "MMR TASK",
                    sortable: true,
                },
                {
                    key: "out_of_service",
                    label: "OUT OF SERVICE",
                    sortable: true,
                },
                {
                    key: "shceduled",
                    label: "SCHEDULED",
                    sortable: true,
                },
                {
                    key: "photo",
                    label: "IMAGE",
                },
                {
                    key: "DOMFP",
                    label: "NOTES",
                    sortable: true,
                },
                {
                    key: "actions",
                    label: "ACTION",
                },
            ],
            /* eslint-disable global-require */
            items: [],
            pdfItems: [],
            maintenance: {
                domicile_id: null,
                tractor_id: null,
                person_completing_work: null,
                date: null,
                odometer: null,
                costs: null,
                mmr_task: null,
                out_of_service: null,
                shceduled: null,
                domfp: null,
                photo: null,
            },
            flag: 0,
            fromDate: null,
            toDate: null,
            requireMaintenance: {
                domicile_id: false,
                tractor_id: false,
                person_completing_work: false,
                date: false,
                odometer: false,
                mmr_task: false,
                out_of_service: false,
            },
            months: [],
            strKeys: ''
        };
    },
    directives: {
        Ripple,
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => ({
                    text: f.label,
                    value: f.key,
                }));
        },
    },
    mounted() {
        // Set the initial number of items
        this.initDate();

        this.setKey();

        this.getMaintenance();

        axios
            .post(`${serverUri}/schedule/getdomicile`, {
                company: getUserData().company_id,
            })
            .then((res) => {
                this.domiciles = res.data;
            });
        
    },
    methods: {
        initDate() {
            this.toDate = new Date();
            this.fromDate = new Date(this.toDate.getFullYear(), this.toDate.getMonth() - 1, 1);
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`;
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        setKey() {
            for (let i = 0; i < this.fields.length-1; i ++) {
                if (i != this.fields.length - 2) this.strKeys += this.fields[i].key +',';
                else this.strKeys += this.fields[i].key;
            }
        },
        ViewPhoto(photo) {

            console.log(photo);
            
            if(photo.search('data'))
            {
                alert('No Photo Found');
                return false;
            } else {

                console.log(photo);
                this.imgPreview = photo;
                this.$refs["PhotoModal"].show();

            }
        },

        ViewNotes(notes){

            console.log(notes);
                this.notesPreview = notes;
                this.$refs["NotesModal"].show();

        },
        getMaintenance() {
            axios
                .post(`${serverUri}/fleet/getmaintenance`, {
                    company: getUserData().company_id,
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    strKey: this.strKeys
                })
                .then((res) => {
                    if(res.data.length > 0) {
                        res.data.map((item) => {
                            item.date = moment(item.date).format('YYYY-MM-DD');
                
                            item.mmr_task = item.mmr_task ? "Yes" : "No";
                            item.out_of_service = item.out_of_service ? "Yes" : "No";
                            item.shceduled = item.shceduled ? "Yes" : "No";
                        });
                    }                        

                    this.items = res.data;
                    this.pdfItems = res.data.map((item) => {
                        return {
                            Domicile: item.domicile_id,
                            Tractor: item.tractor_id,
                            PersonCompletionWork: item.person_completing_work,
                            Notes: item.DOMFP,
                            Date: item.date,
                            Odometer: item.odometer,
                            Cost: item.costs,
                            MMRTask: item.mmr_task,
                            OOS: item.out_of_service,
                            Scheduled: item.shceduled,
                        }
                    })
                    this.totalRows = this.items.length;
                });
        },
        onChangeDomicile() {
            console.log("getuserdata")
            console.log(getUserData());
            console.log("this maintenance domicile id")
            console.log(this.maintenance.domicile_id.domicile);
            axios
                .post(`${serverUri}/fleet/gettractor`, {
                    company: getUserData().company_id,
                    domicile: this.maintenance.domicile_id.domicile
                })
                .then((res) => {
                    this.tractors = res.data;
                });
        },
        onSave(bvModalEvt) {
            if (!this.maintenance.domicile_id || !this.maintenance.person_completing_work || !this.maintenance.odometer ||
                !this.maintenance.mmr_task || !this.maintenance.tractor_id || !this.maintenance.date || !this.maintenance.out_of_service) {
                    
                    if (!this.maintenance.domicile_id) this.requireMaintenance.domicile_id = true;
                    if (!this.maintenance.person_completing_work) this.requireMaintenance.person_completing_work = true;
                    if (!this.maintenance.odometer) this.requireMaintenance.odometer = true;
                    if (!this.maintenance.mmr_task) this.requireMaintenance.mmr_task = true;
                    if (!this.maintenance.tractor_id) this.requireMaintenance.tractor_id = true;
                    if (!this.maintenance.date) this.requireMaintenance.date = true;
                    if (!this.maintenance.out_of_service) this.requireMaintenance.out_of_service = true;

                    bvModalEvt.preventDefault();
                        
                }
            else {
                let mmr_task =
                    this.maintenance.mmr_task == "Yes" ? 1 : 0;
                let out_of_service =
                    this.maintenance.out_of_service == "Yes" ? 1 : 0;
                let shceduled =
                    this.maintenance.shceduled == "Yes" ? 1 : 0;

                let saveValue = {
                    ...this.maintenance,
                    domicile_id : this.maintenance.domicile_id.domicile ? { domicile: this.maintenance.domicile_id.domicile }:{ domicile: this.maintenance.domicile_id },
                    tractor_id: this.maintenance.tractor_id.tractor_id ? { tractor_id: this.maintenance.tractor_id.tractor_id }: { tractor_id: this.maintenance.tractor_id },
                    mmr_task: mmr_task,
                    out_of_service: out_of_service,
                    shceduled: shceduled,
                }

                console.log(this.maintenance);

                if (this.flag) {
                    axios
                        .post(`${serverUri}/fleet/updatemaintenance`, {
                            ...saveValue,
                            company: getUserData().company_id,
                        })
                        .then((res) => {
                            if (res.data.affectedRows) {
                                this.showToast(
                                    "success",
                                    "Success",
                                    "The data has been updated."
                                );
                                this.getMaintenance();
                            } else {
                                this.showToast(
                                    "warning",
                                    "Failure",
                                    "Failed to update the data"
                                );
                            }
                        });

                } else {
                    axios
                        .post(`${serverUri}/fleet/savemaintenance`, {
                            ...saveValue,
                            company: getUserData().company_id,
                        })
                        .then((res) => {
                            this.items.splice(0, this.items.length);
                            this.items = res.data.map((item) => {
                                item.mmr_task = item.mmr_task ? "Yes" : "No";
                                item.out_of_service = item.out_of_service
                                    ? "Yes"
                                    : "No";
                                item.shceduled = item.shceduled ? "Yes" : "No";
                            });

                            this.totalRows = this.items.length;

                            this.showToast(
                                "success",
                                "Success",
                                "The data has been saved."
                            );
                            this.getMaintenance();
                        });
                }

                this.onClear();
            } 
            
        },
        onClose() {
            this.onClear();
        },
        onClear() {
            this.maintenance = {
                domicile_id: null,
                tractor_id: null,
                person_completing_work: null,
                date: null,
                odometer: null,
                costs: null,
                mmr_task: null,
                out_of_service: null,
                shceduled: null,
                domfp: null,
                photo: null,
            }
            this.requireMaintenance = {
                domicile_id: false,
                tractor_id: false,
                person_completing_work: false,
                date: false,
                odometer: false,
                mmr_task: false,
                out_of_service: false,
            }
            this.flag = 0;
        },
        onEdit(data) {
            console.log(data);
            this.maintenance = data;
            this.maintenance.domfp = this.maintenance.DOMFP;

            this.flag = 1;
        },
        onRemove(id) {
            axios.delete(`${serverUri}/fleet/deletefleet/${id}`).then((res) => {
                if (res.data.affectedRows) {
                    this.items.map((item, index) => {
                        if (item.id === id) this.items.splice(index, 1);
                    });

                    this.totalRows = this.items.length;
                    this.showToast(
                        "success",
                        "Success",
                        "The data has been removed"
                    );
                }
            });
        },
        search() {
            if (this.fromDate && this.toDate)
                axios
                    .post(
                        `${serverUri}/fleet/searchmaintenance`, 
                        {
                            company: getUserData().company_id,
                            fromDate: this.fromDate,
                            toDate: this.toDate,
                            strKey: this.strKeys
                        }
                    )
                    .then((res) => {
                        if (res.data.length) {
                            res.data.map((item) => {
                                item.date = moment(item.date).format('YYYY-MM-DD');

                                item.mmr_task = item.mmr_task ? "Yes" : "No";
                                item.out_of_service = item.out_of_service ? "Yes" : "No";
                                item.shceduled = item.shceduled ? "Yes" : "No";
                            });

                            this.items = res.data;
                            this.pdfItems = res.data.map((item) => {
                                return {
                                    Domicile: item.domicile_id,
                                    Tractor: item.tractor_id,
                                    PersonCompletionWork: item.person_completing_work,
                                    Notes: item.DOMFP,
                                    Date: item.date,
                                    Odometer: item.odometer,
                                    Cost: item.costs,
                                    MMRTask: item.mmr_task,
                                    OOS: item.out_of_service,
                                    Scheduled: item.shceduled,
                                }
                            })
                            this.totalRows = this.items.length;
                        }
                        else {
                            this.items = [];
                            this.totalRows = 0;
                        }
                    });
            else
                this.showToast(
                    "warning",
                    "",
                    "Please select the date."
                );
        },
        csvExport() {
            const data = this.items;
            const fileName = "FleetMaintenance";
            const exportType = exportFromJSON.types.csv;

            if (data) exportFromJSON({ data, fileName, exportType });
        },
        exportPDF: function() {
            this.$refs.contentToExport.save();
        },
        showToast(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: "BellIcon",
                    text: text,
                    variant,
                },
            });
        },
    },
    props: {
        avatar: {
            type: String,
        },
    },
    setup(props) {
        const refInputEl = ref(null);
        const previewEl = ref(null);

        const { inputImageRenderer } = useInputImageRenderer(
            refInputEl,
            (base64) => {
                // eslint-disable-next-line no-param-reassign

                props.avatar = base64;
            }
        );

        const onDeleteImage = () => {
            props.avatar = null;
        };

        return {
            onDeleteImage,
            refInputEl,
            previewEl,
            inputImageRenderer,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.hiddenContainer {
    position: absolute;
    left: -10000px;
    top: 0;
}
#PhotoModal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
#PhotoModal .modal-dialog{
    max-width: 800px;
    width: auto !important;
    display: inline-block;
}
#PhotoModal .modal-content{
    text-align:center;
}
</style>
